<template>
  <div class="container-fluid" v-if="club">
    <d-body-top-bar :title="$t('admin.clubs.duplicate-config-club')">
      <div slot="tree">
        <label class="pointer link-area ml-1"
               v-on:click="$router.push('/clubs/detail/' + this.club.id)"
        >
          {{ club.name }} /
        </label>
        <label
          class="pointer inactive-tree link-area ml-1"
        >
          {{ $t('admin.clubs.duplicate-config-club') }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <div class="row pt-5 d-flex justify-content-center">
        <!-- Grid column -->
        <div class="col-md-12">
            <!-- First Step -->
            <div class="row setup-content-3" id="step-5">
              <div class="col-md-12">
                <h3 class="font-weight-bold pl-0 my-4"><strong>{{ $t('admin.clubs.config.choose-duplicate') }}</strong></h3>

                <b-card>
                  <template v-if="club && selectedClub !== null">
                    <b-row no-gutters>
                      <b-col :cols="10">
                        <h4 >
                          <b>{{ selectedClub.name }}</b>
                        </h4>
                      </b-col>
                      <b-col :cols="2">
                        <div class="d-btn-small btn text-center d-btn-danger btn-full-width" v-on:click="removeClub()">Effacer sélection</div>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-if="club && selectedClub === null">
                    <b-row no-gutters>
                      <b-col :cols="12">

                        <label class="field-name-class">
                          {{ $t('admin.clubs.config.search') }}
                        </label>
                        <d-text-field
                          v-model="searchClub"
                          size="sm"
                          class-name="background-light-blue-inputs"
                          :type="text"
                        />
                      </b-col>
                    </b-row>

                    <b-row class="mt-3 row-select-club" :key="index + '-club'" v-for="(club, index) in clubs" v-on:click="selectClub(club)" >
                      <b-col :cols="8">
                        {{ club.name }}
                      </b-col>
                      <b-col :cols="4">

                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </div>
            </div>


            <!-- Second Step -->
            <div class="row setup-content-3" id="step-6" v-if="selectedClub !== null">
              <div class="col-md-12">
                <h3 class="font-weight-bold pl-0 my-4">
                  <strong>
                    {{ $t('admin.clubs.config.informations-to-duplicate') }}
                  </strong>
                </h3>
                <b-card>
                  <b-row>
                    <b-col :cols="10">
                    </b-col>
                    <b-col :cols="2">
                      <div class="d-btn-small btn text-center d-btn-primary btn-full-width" v-on:click="selectAll()">Tous</div>
                    </b-col>
                  </b-row>

                  <div class="item-duplicate" v-for="item in duplicateItems">
                    <b-row class="mt-3 row-item-duplicate" v-on:click="choiceItem(item.code)">
                      <b-col :cols="8">
                        {{ item.label }}
                      </b-col>
                      <b-col :cols="4" class="text-right">
                        <span  data-v-30085ac6="" class="pointer icons-body-size bg-doinsport-primary fa fa-check" v-if="itemIn(item.code)"></span>
                        <span  data-v-30085ac6="" class="text-right pointer icons-body-size bg-doinsport-danger fa fa-times" v-if="!itemIn(item.code)"></span>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
                <button v-if="selectedItems.length > 0" v-on:click="confirm()" class="btn btn-success btn-rounded float-right" type="submit">{{ $t('general.actions.validate') }}</button>
              </div>
            </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </div>
</template>

<script>
import {getAllClub, getClub, postDuplicateClub} from "@api/services/club/club.api";

export default {
  components: {
  },
  data: () => ({
    duplicateItems: [
      {code: 'form', label: 'Formulaire'},
      {code: 'formField', label: 'Champs de Formulaire'},
      {code: 'subscriptionPlan', label: 'Abonnement'},
      {code: 'shopCategory', label: 'Catégorie boutique'},
      {code: 'shopProduct', label: 'Produit boutique'},
      {code: 'walletRefill', label: 'Recharge Wallet'},
      {code: 'bookingRestriction', label: 'Restriction de réservation'},
      {code: 'paymentToken', label: 'Crédit'},
      {code: 'paymentTokenPack', label: 'Pack de crédit'},
      {code: 'playground', label: 'Terrain'},
      {code: 'playgroundOption', label: 'Option de terrain'},
      {code: 'playgroundTimetable', label: 'Calendrier'},
      {code: 'playgroundTimetableBlock', label: 'Block horaires'},
      {code: 'playgroundTimetableBlockPrice', label: 'Prestation'}
    ],
    selectedClub: null,
    selectedItems: [],
    clubs: [],
    club: null,
    step: 0,
    searchClub: '',
  }),
  watch : {
    searchClub(value) {
      if (value != '' && value.length > 3) {
        getAllClub(1, 10, value).then(resp => {
          this.clubs = resp.data["hydra:member"];
        }).catch(err => {
          console.error(err);
        });
      } else {
        this.clubs = [];
      }
    },
  },
  methods: {
    selectAll() {
      this.selectedItems = [];
      this.duplicateItems.forEach(item => {
        this.selectedItems.push(item.code);
      });
    },
    choiceItem(code) {
      if (this.itemIn(code)) {
        this.removeItem(code);
      } else {
        this.addItem(code);
      }
    },
    addItem(code) {
      this.selectedItems.push(code);
    },
    removeItem(code) {
      const data = [];

      this.selectedItems.forEach(el => {
        if (el !== code) {
          data.push(el);
        }
      });

      this.selectedItems = data;
    },
    itemIn(code) {
      let exist = false;
      this.selectedItems.forEach(el => {
        if (el === code) {
          exist = true;
        }
      });
      return exist;
    },
    removeClub() {
      this.selectedClub = null;
      this.selectedItems = [];
    },
    selectClub(club) {
      this.selectedClub = club;
      this.searchClub = '';
      this.clubs = [];
    },
    confirm() {
      const data = {
        destination: "/clubs/" + this.club.id,
        source: "/clubs/" + this.selectedClub.id,
        blocks: this.selectedItems
      };

      postDuplicateClub(data).then(resp => {
        if (resp) {
          this.$vs.notify({ time: 10000, title: 'Duplication config Club',text: 'La demande de duplication à bien été envoyé.', color: 'success',position: 'top-right'});
          this.$router.push('/clubs/detail/' + this.club.id);
        }
      }).catch(err => {console.error('oups, error : ', err)})
    }

  },
  created() {
    if (this.$route.params.id) {
      this.isUpdate = true;
      getClub('/clubs/' + this.$route.params.id).then(response => {
        if (response.data) {
          this.club = response.data;
          if (this.club.address) {
            if (this.club.address.length > 0) {
              this.club.address = this.club.address[0];
            }
          }
        }
      }).catch(err => {
        console.error(err);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.row-item-duplicate {
  cursor: pointer;
  margin: unset;
  &:hover {
    background-color: whitesmoke;
    font-weight: bold;
  }
}
.row-select-club {
  cursor: pointer;
  padding: 10px;
  margin: unset;
  &:hover {
    background-color: whitesmoke;
    font-weight: bold;
  }
}
</style>


